<template>
  <div>
    <v-card class="text-center">
        <v-toolbar color="red" dark flat>
          <v-card-title class="justify-center white--text">
            {{ deleteTitle }}を削除しますか？
          </v-card-title>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="close">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="mt-8 pb-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <p>
                削除後は復元できません。<br>
                削除するには "<span style="color: red;">完全に削除</span>" と入力して下さい。
              </p>
              <v-text-field v-model="deleteItemName" label='"完全に削除" と入力' style="width: 200px;" class="mx-auto"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-col cols="5">
            <v-btn :disabled="loading" @click="close" block outlined>キャンセル</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn color="red" class="white--text" @click="deleteItem" :disabled="this.deleteItemName !== checkName()"
              block>削除
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card v-if="isError">
        <DialogError v-on:closeErrorEmit="close" text="エラーが発生し,削除が完了しませんでした。" />
      </v-card>
      <loading v-if="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
const DialogError = () => import('@/components/DialogError.vue');
const Loading = () => import("@/components/Loading.vue");
export default {
  name: 'DialogDelete',
  props: {
    name: String,
    deletionType: String,
    deletionTitle: String,
  },
  components: {
    DialogError,
    Loading
  },
  data() {
    return {
      deleteItemName: null,
      loading: false,
      isError: false,
    }
  },
  computed: {
    deleteTitle() {
      return this.deletionTitle + ' "' + this.name + '" ';
    },
  },
  methods: {
    checkName() {
      return "完全に削除";
    },
    async deleteItem() {
      this.loading = true;
      try {
        let url = "";
        if (this.deletionTitle === "プロジェクト") {
          url = `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.deletionType}`;
        } else if (this.deletionTitle === "バージョン") {
          url = `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.deletionType}`;
        }
        const response = await axios.delete(url, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        this.loading = false;
        this.$emit('deleteItemExeEmit');
        if (this.deletionTitle === "プロジェクト") {
          this.$store.dispatch("setProjectInfo", this.$store.state.auth.login);
        }
        this.deleteItemName = null;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    close() {
      this.deleteItemName = null;
      this.isError = false;
      this.loading = false;
      this.$emit('closeDeleteEmit');
    }
  },
}
</script>
